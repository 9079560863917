import React, { useEffect } from "react";
import Stepper from "./components/stepper/Stepper";
import { useAccordions } from "./hooks/useAccordions";
import CategoryGroup from "./components/category/CategoryGroup";
import Product from "./components/product/Product";
import Summary from "./components/panel/Summary";
import { useAppDispatch, useAppSelector } from "./state/store";
import { fetchProducts, selectAllProducts } from "./state/reducers/product";
import {
  fetchCategories,
  selectAllCategories,
} from "./state/reducers/category";
import { createOrRestoreCart } from "./state/reducers/cart";
import Description from "./components/panel/Description";
import Products from "./containers/Products";
import Popout from "./components/popout/Popout";
import Personalization from "./containers/Personalization";
import content from "./content";
import { downloadTranslations } from "./state/reducers/app";

function App() {
  const descriptionOpen = useAppSelector((state) => state.app.openDescriptions);
  const step = useAppSelector((state) => state.app.step);
  const [refresh, setRefresh] = React.useState(false);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(downloadTranslations()).then((res) => {
      content.setContent(res.payload);
      setRefresh(!refresh);
    });
  }, []);

  return (
    <div key={refresh + "app"} className="App">
      <section className="configuratorSection">
        <div className="center">
          <div className="configuratorContent">
            <div className="configuratorBox">
              <Stepper step={step} />
              <h2 className="sectionTitle">{content.getValue("header")}</h2>
              <p className="sectionText">{content.getValue("description")}</p>
              {step === 1 ? <Products /> : <Personalization />}
            </div>
            {descriptionOpen ? <Description /> : <Summary />}
          </div>
        </div>
      </section>
      <Popout />
      <script type="text/javascript" src="js/app.js"></script>
    </div>
  );
}

export default App;
