import React from "react";
import { useAppDispatch, useAppSelector } from "../../state/store";
import { toggleDescriptions } from "../../state/reducers/app";
import content from "../../content";

function Description({}: IDescription) {
  const dispatch = useAppDispatch();
  const product = useAppSelector((state) => state.app.descriptionProduct);

  const volume = product?.attributes.find(
    (a) => a.attribute === "volume"
  )?.value;

  const weight = product?.attributes.find(
    (a) => a.attribute === "weight"
  )?.value;

  let text = "";

  if (volume) {
    text = `${volume}${content.getValue("ml")}`;
  }

  if (weight) {
    if (text) {
      text += `/${weight}${content.getValue("kg")}`;
    } else {
      text = `${weight}${content.getValue("kg")}`;
    }
  }

  const closeDescription = () => {
    dispatch(toggleDescriptions());
  };

  return (
    <div className="productInformation">
      <img
        onClick={closeDescription}
        style={{ cursor: "pointer" }}
        className="closeIcon"
        src="images/closeBlack.svg"
        alt="closeIcon"
      />
      <p className="summarySectionTitle">{content.getValue("product_info")}</p>
      <div className="informationBox">
        <p className="priceBold priceBold-margin">
          {product?.name || product?.integration.name}
        </p>
        <p className="priceBold priceBold-margin">{text}</p>
        <p
          className="summaryRegularText resetAll"
          style={{
            overflow: "hidden",
            wordBreak: "break-all",
          }}
          dangerouslySetInnerHTML={{
            __html:
              product?.attributes.find((a) => a.attribute === "description")
                ?.value || "",
          }}
        />
      </div>
    </div>
  );
}

export interface IDescription {}

export default Description;
