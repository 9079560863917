class Content {
  content: IContent[] = [];
  lang: string = "pl";

  public setContent(content: IContent[]) {
    this.lang = content[0].lang;
    this.content = content;
  }

  public getLoaded() {
    return this.content.length !== 0;
  }

  public getValue(slug: string): string {
    return this.content.find((c) => c.slug === slug)?.value || slug;
  }

  public getLang(): string {
    return this.lang;
  }
}

const content = new Content();

export interface IContent {
  slug: string;
  value: string;
  lang: string;
}

export default content;
