import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import productsReducer from "./reducers/product";
import categoriesReducer from "./reducers/category";
import appReducer from "./reducers/app";
import cartReducer from "./reducers/cart";

const store = configureStore({
  reducer: {
    products: productsReducer,
    categories: categoriesReducer,
    app: appReducer,

    cart: cartReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export default store;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
