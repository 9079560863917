import React, { useEffect, useLayoutEffect } from "react";
import { useAccordions } from "../hooks/useAccordions";
import { useAppDispatch, useAppSelector } from "../state/store";
import {
  fetchCategories,
  selectAllCategories,
} from "../state/reducers/category";
import { fetchProducts, selectAllProducts } from "../state/reducers/product";
import { createOrRestoreCart } from "../state/reducers/cart";
import CategoryGroup from "../components/category/CategoryGroup";
import Product from "../components/product/Product";

function Products({}: IProducts) {
  const dispatch = useAppDispatch();

  const categories = useAppSelector(selectAllCategories);
  const products = useAppSelector(selectAllProducts);
  const total = useAppSelector((state) => state.cart.total);

  useEffect(() => {
    dispatch(createOrRestoreCart());
    dispatch(fetchProducts());
    dispatch(fetchCategories());
  }, []);

  return (
    <ul className="accordion">
      {categories.map((category, i, arr) => (
        <CategoryGroup
          disabled={total === 0 && i !== 0}
          key={category.id}
          name={category.name || category.integration.name}
        >
          {products
            .filter((p) => p?.categories?.includes(category.id))
            .map((product) => (
              <Product
                key={product.id}
                base={i === 0}
                cart={i === arr.length - 1}
                product={product}
                category={category}
              />
            ))}
        </CategoryGroup>
      ))}
    </ul>
  );
}

export interface IProducts {}

export default Products;
