import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Product } from "./product";

export interface PopoutI {
  open: boolean;
  title: string;
  action: () => void;
}

export interface AppState {
  step: number;
  openDescriptions: boolean;
  descriptionProduct: Product | null;
  popout: PopoutI;
}

export const downloadTranslations = createAsyncThunk(
  "app/downloadTranslations",
  async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/api/content/all"
    );
    return await response.json();
  }
);

const appSlice = createSlice({
  name: "app",
  initialState: {
    step: 1,
    openDescriptions: false,
    descriptionProduct: null,
    popout: {
      open: false,
      title: "",
      action: () => {},
    },
  } as AppState,
  reducers: {
    toggleDescriptions: (state) => {
      state.openDescriptions = !state.openDescriptions;
    },
    openPopout: (state, action) => {
      state.popout = action.payload;
    },
    setStep: (state, action) => {
      state.step = action.payload;
    },
    closePopout: (state) => {
      state.popout = {
        open: false,
        title: "",
        action: () => {},
      };
    },
    setDescription: (state, action) => {
      state.descriptionProduct = action.payload;
    },
  },
});

export const {
  toggleDescriptions,
  closePopout,
  setStep,
  openPopout,
  setDescription,
} = appSlice.actions;
export default appSlice.reducer;
