import React from 'react'

function Step({activeStep, step, text}: IStep) {

 return (
   <div className="step">
     <div className={`step__number ${activeStep !== step? 'step__number-inactive':''}`}>{activeStep > step? <img src="images/checkmark.svg" alt="checkmarkIcon"/>:step}</div>
     <p className="step__text">{text}</p>
   </div>
 );
};

export interface IStep {
  step: number;
  text: string
  activeStep: number;
}

export default Step