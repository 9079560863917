import React from "react";
import { useAppDispatch, useAppSelector } from "../../state/store";
import { closePopout } from "../../state/reducers/app";
import content from "../../content";

function Popout({}: IPopout) {
  const popout = useAppSelector((state) => state.app.popout);
  const dispatch = useAppDispatch();

  const close = () => {
    dispatch(closePopout());
  };

  return popout.open ? (
    <div className="popupSection">
      <div className="popup">
        <p className="popup_message">{popout.title}</p>
        <div className="popup_controls">
          <button onClick={close} className="popup_close">
            {content.getValue("cancel")}
          </button>
          <button onClick={popout.action} className="popup_confirm">
            {content.getValue("confirm")}
          </button>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}

export interface IPopout {}

export default Popout;
