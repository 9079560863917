import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface Product {
  id: number;
  createdAt: SDate;
  updatedAt: SDate;
  updateSource: string;
  name: string;
  deleted: boolean;
  active: boolean;
  image: string;
  price: string;
  integration: ProductIntegration;
  attributes: ProductAttribute[];
  categories: number[];
  quantity: number;
}

export interface ProductAttribute {
  id: number;
  attribute: string;
  value: string;
}

export interface ProductIntegration {
  id: number;
  price: string;
  image: string;
  active: boolean;
  name: string;
}

export type SDate = {
  date: string;
  timezone_type: number;
  timezone: string;
};

export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async (): Promise<Product[]> => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/api/product/",
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return await response.json();
  }
);

const productsAdapter = createEntityAdapter<Product>({
  selectId: (product) => product.id,
});

const productSlice = createSlice({
  name: "product",
  initialState: {
    products: productsAdapter.getInitialState(),
    loading: false,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProducts.pending, (state, action) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      productsAdapter.upsertMany(state.products, action.payload);
    });
    builder.addCase(fetchProducts.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default productSlice.reducer;

export const { selectAll: selectAllProducts } =
  productsAdapter.getSelectors<RootState>((state) => state.products.products);
