import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { openPopout, setStep } from "./app";
import { selectAllCategories } from "./category";
import { selectAllProducts } from "./product";
import content from "../../content";

export interface Cart {
  items: any;
  total: number;
  id: string;
}

const cartAdapter = createEntityAdapter<any>();

export const placeOrderCart = createAsyncThunk<any, any, { state: RootState }>(
  "cart/placeOrder",
  async (_, thunkAPI) => {
    await fetch(
      process.env.REACT_APP_API_URL +
        "/api/cart/" +
        thunkAPI.getState().cart.id,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }
);

export const tryUpgradeBasket = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("cart/tryUpgradeBasket", async (_, { dispatch, getState }) => {
  const categories = selectAllCategories(getState());
  const products = selectAllProducts(getState());
  const basketVolume = Number(getState().cart.basketVolume);

  const fcategory = categories[0];

  const baseProducts = products.filter((p) =>
    p?.categories?.includes(fcategory.id)
  );

  const betterBasket = baseProducts.find(
    (p) =>
      (p.attributes.find((a: any) => a.attribute === "volume")?.value || 0) >
      basketVolume
  );

  if (betterBasket) {
    dispatch(upgradeBasket(betterBasket));
  }
});

export const upgradeBasket = createAsyncThunk<any, any, { state: RootState }>(
  "cart/upgradeBasket",
  async (basket, { dispatch, getState }) => {
    const items = selectAllCartItems(getState());
    const basketVolume = Number(
      basket?.attributes?.find((a: any) => a.attribute === "volume")?.value
    );
    const currentBasket = items.find((i: any) => !!i.base);

    const total = items
      ?.filter((i) => !i?.base)
      ?.reduce((acc, item) => {
        return (
          acc +
          Number(
            (
              (((item?.attributes?.find((a: any) => a?.attribute === "volume")
                ?.value || 0) *
                item.quantity) /
                basketVolume) *
              100
            ).toFixed(0)
          )
        );
      }, 0);

    if (total < 80) {
      dispatch(addComponentToCart({ product: basket, base: true }));
      dispatch(removeComponentToCart({ product: currentBasket }));
    } else {
      dispatch(
        openPopout({
          open: true,
          title: content.getValue("reset_title"),
          action: () => dispatch(clearCart()),
        })
      );
    }
  }
);

export const recalculateCart = createAsyncThunk<
  any,
  void,
  { state: RootState }
>("cart/recalculate", async (_, thunkAPI) => {
  const items = selectAllCartItems(thunkAPI.getState());
  const basketVolume = Number(thunkAPI.getState().cart.basketVolume);

  const total = items
    ?.filter((i) => !i?.base)
    ?.reduce((acc, item) => {
      return (
        acc +
        Number(
          (
            (((item?.attributes?.find((a: any) => a?.attribute === "volume")
              ?.value || 0) *
              item.quantity) /
              basketVolume) *
            100
          ).toFixed(0)
        )
      );
    }, 0);

  return { total };
});

export const clearCart = createAsyncThunk<void, void, { state: RootState }>(
  "cart/clearCart",
  async (args: any, thunkAPI) => {
    const { dispatch } = thunkAPI;

    dispatch(
      openPopout({
        open: false,
      })
    );
    dispatch(clearCartState());
    dispatch(recalculateCart());
    dispatch(updateCart());
    dispatch(setStep(1));
  }
);

export const updateProductQuantity = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("cart/updateProductQuantity", async (component: any, thunkAPI) => {
  const { dispatch } = thunkAPI;

  dispatch(
    updateComponentState({
      changes: { quantity: component.quantity },
      id: component.id,
    })
  );
  dispatch(recalculateCart());
  dispatch(updateCart());
});

export const updateCart = createAsyncThunk<void, void, { state: RootState }>(
  "cart/updateCart",
  async (_, thunkAPI) => {
    const cart = thunkAPI.getState().cart;

    await fetch(process.env.REACT_APP_API_URL + "/api/cart/" + cart.id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(cart),
    });

    return;
  }
);

export const addComponentToCart = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("cart/addComponentToCart", async (component: any, thunkAPI) => {
  const { dispatch } = thunkAPI;

  if (component.cart) {
    const card = getCurrentCard(thunkAPI.getState());
    if (card) {
      dispatch(removeComponentToCart({ product: card, cart: true }));
    }
    dispatch(addComponentToCartState(component));
  } else {
    dispatch(addComponentToCartState(component));
  }
  dispatch(recalculateCart());
  dispatch(updateCart());
});

export const removeComponentToCart = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("cart/removeComponentToCart", async (component: any, thunkAPI) => {
  const { dispatch } = thunkAPI;

  if (component.base) {
    dispatch(
      openPopout({
        open: true,
        title: content.getValue("reset_title"),
        action: () => dispatch(clearCart()),
      })
    );
  } else {
    dispatch(removeComponentToCartState(component));
    dispatch(recalculateCart());
    dispatch(updateCart());
  }
});

export const createOrRestoreCart = createAsyncThunk("cart/create", async () => {
  if (localStorage.getItem("cart")) {
    const response = await fetch(
      process.env.REACT_APP_API_URL +
        "/api/cart/" +
        localStorage.getItem("cart"),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status !== 404) {
      const cart = await response.json();
      localStorage.setItem("cart", cart.id);

      return cart;
    }
  }
  const response = await fetch(process.env.REACT_APP_API_URL + "/api/cart/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const cart = await response.json();

  localStorage.setItem("cart", cart.id);

  return cart;
});

export const uploadFile = createAsyncThunk<any, any, { state: RootState }>(
  "cart/uploadFile",
  async ({ file, type }, thunkApi) => {
    const formData = new FormData();
    formData.append("image", file);

    const response = await fetch(
      process.env.REACT_APP_API_URL + "/api/product/image",
      {
        method: "POST",
        body: formData,
      }
    );

    return await response.json();
  }
);

export const updateCartSettings = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("cart/updateCartSettings", async (settings: any, thunkAPI) => {
  const { dispatch } = thunkAPI;

  dispatch(updateCartSettingsState(settings));
  dispatch(updateCart());
});

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    items: cartAdapter.getInitialState(),
    total: 0,
    volumePercent: 0,
    basketVolume: 0,
    basket: {
      ownLogo: false,
      ownText: false,
      logo: "",
      text: "",
    },
    card: {
      ownLogo: false,
      ownText: false,
      logo: "",
      text: "",
    },
    id: "",
  },
  reducers: {
    clearCartState: (state) => {
      state.items = cartAdapter.getInitialState();
      state.total = 0;
      state.volumePercent = 0;
      state.basketVolume = 0;
      state.basket = {
        ownLogo: false,
        ownText: false,
        logo: "",
        text: "",
      };
      state.card = {
        ownLogo: false,
        ownText: false,
        logo: "",
        text: "",
      };
    },
    removeComponentToCartState: (state, action) => {
      cartAdapter.removeOne(state.items, action.payload.product.id);
      state.total -= 1;
    },
    updateComponentState: (state, action) => {
      cartAdapter.updateOne(state.items, {
        id: action.payload.id,
        changes: action.payload.changes,
      });
    },
    updateCartSettingsState: (state, action) => {
      state[action.payload.type as "basket" | "card"] = action.payload.settings;
    },
    addComponentToCartState: (state, action) => {
      cartAdapter.addOne(state.items, {
        ...action.payload.product,
        base: action.payload.base,
        cart: action.payload.cart,
        quantity: 1,
      } as any);

      if (action.payload.base) {
        state.basketVolume = action.payload.product?.attributes?.find(
          (a: any) => a.attribute === "volume"
        )?.value;
      }

      state.total += 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createOrRestoreCart.fulfilled, (state, action) => {
      console.log(action.payload);
      state.id = action.payload.id;
      state.total = action.payload.total;
      state.basketVolume = action.payload.basketVolume;
      state.basket = action.payload.basket;
      state.card = action.payload.card;
      state.volumePercent = action.payload.volumePercent;
      if (!("length" in action.payload.items)) {
        state.items = action.payload.items;
      }
    });
    builder.addCase(recalculateCart.fulfilled, (state, action) => {
      state.volumePercent = action.payload.total;
    });
  },
});

export const getCartProduct = (productId: number) => {
  return function (state: RootState) {
    return cartAdapter
      .getSelectors((state: RootState) => state.cart.items)
      .selectById(state, productId);
  };
};

export const getCurrentCard = (state: RootState) => {
  const items = selectAllCartItems(state);
  return items?.find((i) => !!i?.cart);
};

export const getFullPrice = (state: RootState) => {
  const items = selectAllCartItems(state);

  return items.reduce((acc, item) => {
    return (
      acc +
      (Number(item?.price) || Number(item?.integration?.price) || 0) *
        item?.quantity
    );
  }, 0);
};

export const getCurrentBasket = (state: RootState) => {
  const items = selectAllCartItems(state);

  return items?.find((item) => !!item?.base);
};

export default cartSlice.reducer;

export const { selectAll: selectAllCartItems } =
  cartAdapter.getSelectors<RootState>((state) => state.cart.items);
export const {
  clearCartState,
  updateComponentState,
  removeComponentToCartState,
  addComponentToCartState,
  updateCartSettingsState,
} = cartSlice.actions;
