import React from "react";
import { useAppDispatch, useAppSelector } from "../../state/store";
import {
  clearCart,
  getFullPrice,
  placeOrderCart,
  removeComponentToCart,
  selectAllCartItems,
  tryUpgradeBasket,
} from "../../state/reducers/cart";
import { openPopout, setStep } from "../../state/reducers/app";
import content from "../../content";
import Spinner from "./Spinner";

function Summary({}: ISummary) {
  const dispatch = useAppDispatch();
  const items = useAppSelector(selectAllCartItems);
  const volume = useAppSelector((state) => state.cart.volumePercent);
  const basketVolume = useAppSelector((state) => state.cart.basketVolume);
  const base = items.find((item) => item?.base);
  const step = useAppSelector((state) => state.app.step);
  const price = useAppSelector(getFullPrice);
  const [loading, setLoading] = React.useState(false);

  const deselect = (item: any) => () => {
    dispatch(removeComponentToCart({ product: item, base: false }));
  };

  const upgrade = () => {
    dispatch(tryUpgradeBasket());
  };

  const placeOrder = () => {
    setLoading(true);
    dispatch(placeOrderCart(""));
  };

  const clamp = (
    num: number,
    in_min: number,
    in_max: number,
    out_min: number,
    out_max: number
  ) => {
    return ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
  };

  const clear = () => {
    dispatch(
      openPopout({
        open: true,
        title: content.getValue("reset_title"),
        action: () => dispatch(clearCart()),
      })
    );
  };

  const defaultCart = {
    base: {
      name: "Koszyk wikinowy",
      price: 123,
      volume: 43,
    },
    components: [
      {
        name: "Miód wrzosowy",
        price: 0,
        volume: 33,
        quantity: 1,
      },
      {
        name: "Miód ziemniakowy",
        price: "0",
        volume: 33,
        quantity: 1,
      },
    ],
  };

  return (
    <div
      style={
        items.length === 0
          ? {
              transition: "all 0.5s ease-in-out",
              filter: "blur(2px) grayscale(0.5)",
              pointerEvents: "none",
            }
          : {}
      }
      className="configuratorSummary"
    >
      <p className="summaryTitle">{content.getValue("cart_summary")}</p>
      <div className="chosenSection">
        <p className="summarySectionTitle">
          {content.getValue("selected_basket")}
        </p>
        <div className="chosenItem">
          <img
            onClick={clear}
            src="images/trash.svg"
            alt="trashIcon"
            className="trashIcon"
          />
          {items.length === 0 ? (
            <p className="nameInsideLabel">
              {defaultCart.base.name}
              {defaultCart.base.volume}000 ml
            </p>
          ) : (
            <p className="nameInsideLabel">
              {base?.name || base?.integration?.name}{" "}
              {
                base?.attributes?.find((a: any) => a.attribute === "volume")
                  ?.value
              }
              {content.getValue("ml")}
            </p>
          )}
          <svg
            className={"greenBasketIcon"}
            width="42"
            height="40"
            viewBox="0 0 42 40"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18.1281 14.9951H3.30322C1.8004 14.9951 0.702713 16.4149 1.0811 17.8693L6.0093 36.8121C6.27253 37.8239 7.18599 38.5301 8.23143 38.5301H33.765C34.8104 38.5301 35.7239 37.8239 35.9871 36.8121L40.9153 17.8693C41.2937 16.4149 40.196 14.9951 38.6932 14.9951H23.8683V16.1432H38.6932C39.4446 16.1432 39.9934 16.8531 39.8043 17.5803L34.876 36.5231C34.7444 37.029 34.2877 37.3821 33.765 37.3821H8.23143C7.70871 37.3821 7.25198 37.029 7.12037 36.5231L2.19216 17.5803C2.00297 16.8531 2.55181 16.1432 3.30322 16.1432H18.1281V14.9951Z"
              fill="#B2DFE0"
            />
            <rect
              x="18.702"
              y="2.36687"
              width="4.59219"
              height="20.6649"
              rx="2.2961"
              stroke="#B2DFE0"
              strokeWidth="1.14805"
            />
            <path
              d="M2.62946 19.702H18.7021"
              stroke="#B2DFE0"
              strokeWidth="1.14805"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23.2942 19.7021H39.3669"
              stroke="#B2DFE0"
              strokeWidth="1.14805"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <mask
              id="mask0"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="1"
              y="14"
              width="40"
              height="25"
            >
              <path
                d="M1.63667 17.7249C1.35288 16.6341 2.17614 15.5692 3.30326 15.5692H38.6932C39.8203 15.5692 40.6436 16.6341 40.3598 17.7249L35.4316 36.6677C35.2342 37.4265 34.5491 37.9561 33.765 37.9561H8.23147C7.44739 37.9561 6.76229 37.4265 6.56488 36.6677L1.63667 17.7249Z"
                fill="#C4C4C4"
                stroke="#009698"
                strokeWidth="1.14805"
              />
            </mask>
            <g mask="url(#mask0)">
              <path
                d="M35.3929 19.7022L30.6241 37.6744"
                stroke="#B2DFE0"
                strokeWidth="1.14805"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M30.6244 19.7022L25.8556 37.6744"
                stroke="#B2DFE0"
                strokeWidth="1.14805"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M25.855 19.7022L21.0862 37.6744"
                stroke="#B2DFE0"
                strokeWidth="1.14805"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M20.2252 23.0316L16.3174 37.6745"
                stroke="#B2DFE0"
                strokeWidth="1.14805"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.3179 19.7022L11.5491 37.6744"
                stroke="#B2DFE0"
                strokeWidth="1.14805"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.5486 19.7022L7.02282 37.7457"
                stroke="#B2DFE0"
                strokeWidth="1.14805"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.78015 19.7022L4.39574 29.972"
                stroke="#B2DFE0"
                strokeWidth="1.14805"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <g clipPath="url(#clip0)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.1279 14.9969H3.30322C1.8004 14.9969 0.702713 16.4167 1.0811 17.8712L6.0093 36.814C6.27253 37.8257 7.18599 38.5319 8.23143 38.5319H33.765C34.8104 38.5319 35.7239 37.8257 35.9871 36.814L40.9153 17.8712C41.2937 16.4167 40.196 14.9969 38.6932 14.9969H23.8682V16.145H38.6932C39.4446 16.145 39.9934 16.8549 39.8042 17.5821L34.876 36.5249C34.7444 37.0308 34.2877 37.3839 33.765 37.3839H8.23143C7.70871 37.3839 7.25198 37.0308 7.12037 36.5249L2.19216 17.5821C2.00297 16.8549 2.55181 16.145 3.30322 16.145H18.1279V14.9969Z"
                fill="#009698"
              />
              <mask
                id="mask1"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="1"
                y="14"
                width="40"
                height="25"
              >
                <path
                  d="M1.63667 17.7266C1.35288 16.6358 2.17614 15.571 3.30326 15.571H38.6932C39.8203 15.571 40.6436 16.6358 40.3598 17.7266L35.4316 36.6694C35.2342 37.4283 34.5491 37.9579 33.765 37.9579H8.23147C7.44739 37.9579 6.76229 37.4283 6.56488 36.6694L1.63667 17.7266Z"
                  fill="#C4C4C4"
                  stroke="#009698"
                  strokeWidth="1.14805"
                />
              </mask>
              <g mask="url(#mask1)">
                <path
                  d="M35.3929 19.7041L30.6241 37.6763"
                  stroke="#009698"
                  strokeWidth="1.14805"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M30.6244 19.7041L25.8556 37.6763"
                  stroke="#009698"
                  strokeWidth="1.14805"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M25.855 19.7041L21.0862 37.6763"
                  stroke="#009698"
                  strokeWidth="1.14805"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M20.2252 23.0336L16.3174 37.6764"
                  stroke="#009698"
                  strokeWidth="1.14805"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16.3179 19.7041L11.5491 37.6763"
                  stroke="#009698"
                  strokeWidth="1.14805"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.5486 19.7041L7.02282 37.7476"
                  stroke="#009698"
                  strokeWidth="1.14805"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.78015 19.7041L4.39574 29.9739"
                  stroke="#009698"
                  strokeWidth="1.14805"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </g>
            <defs>
              <clipPath id="clip0">
                <rect
                  width="40"
                  height="40"
                  fill="white"
                  transform={`translate(0.333313 ${clamp(
                    items.length === 0 ? defaultCart.base.volume : volume,
                    0,
                    100,
                    40,
                    15
                  )})`}
                />
              </clipPath>
            </defs>
          </svg>

          <p
            className="chosenItemPercent counterNum"
            style={
              {
                "--num": items.length === 0 ? defaultCart.base.volume : volume,
              } as any
            }
          >
            %
          </p>
        </div>
        {(volume >= 80 || items.length === 0) && (
          <div className="warningLabel">
            <img
              className="warningIcon"
              src="images/warning.svg"
              alt="warningIcon"
            />
            <p onClick={upgrade} className="warningMessage">
              {content.getValue("volume_warring")} –{" "}
              <u>{content.getValue("upgrade_size")}</u>
            </p>
          </div>
        )}
      </div>
      <div className="productsSection">
        <p className="summarySectionTitle">{content.getValue("products")}</p>
        <div className="productTitles">
          <p className="summaryRegularText">
            {content.getValue("product_name")}
          </p>
          <p className="summaryRegularText summaryRegularText_margin">
            {content.getValue("count")}
          </p>
          <p className="summaryRegularText">{content.getValue("volume")}</p>
        </div>
        {items.length === 0 ? (
          <>
            <div className="productLabel">
              <img
                className="productLabelTrashIcon"
                src="images/trash.svg"
                alt="trashIcon"
              />
              <p className="summaryRegularText summaryRegularText_wide">
                Miód wrzosowy
              </p>
              <p className="summaryRegularText summaryRegularText_margin02">
                1
              </p>
              <p className="summaryRegularText">33%</p>
            </div>
            <div className="productLabel">
              <img
                className="productLabelTrashIcon"
                src="images/trash.svg"
                alt="trashIcon"
              />
              <p className="summaryRegularText summaryRegularText_wide">
                Miód wrzosowy
              </p>
              <p className="summaryRegularText summaryRegularText_margin02">
                1
              </p>
              <p className="summaryRegularText">33%</p>
            </div>
          </>
        ) : (
          items
            ?.filter((item) => !item?.base)
            ?.map((item) => (
              <div className="productLabel" key={item?.id}>
                <img
                  onClick={deselect(item)}
                  className="productLabelTrashIcon"
                  src="images/trash.svg"
                  alt="trashIcon"
                />
                <p className="summaryRegularText summaryRegularText_wide">
                  {item?.name || item?.integration?.name}
                </p>
                <p
                  className="summaryRegularText summaryRegularText_margin02 counterNum"
                  style={{ "--num": item?.quantity } as any}
                />
                <p
                  className="summaryRegularText counterNum"
                  style={
                    {
                      "--num": (
                        (((item?.attributes?.find(
                          (a: any) => a?.attribute === "volume"
                        )?.value || 0) *
                          item?.quantity) /
                          basketVolume) *
                        100
                      ).toFixed(0),
                    } as any
                  }
                >
                  %
                </p>
              </div>
            ))
        )}
      </div>
      <div className="priceSection">
        <p className="summarySectionTitle">{content.getValue("price")}</p>
        <div className="priceLabel">
          <p className="summaryRegularText">{content.getValue("netto")}</p>
          <p className="priceBold">
            {items.length === 0 ? "100.00" : (price - price * 0.23).toFixed(2)}{" "}
            {content.getValue("currency")}
          </p>
        </div>
        <div className="priceLabel">
          <p className="summaryRegularText">{content.getValue("brutto")}</p>
          <p className="priceBold">
            {items.length === 0 ? "123.00" : price.toFixed(2)}{" "}
            {content.getValue("currency")}
          </p>
        </div>
      </div>
      <div className="summaryButtons">
        <div onClick={clear} className="resetButton">
          <p className="summarySectionTitle summarySectionTitle-resetButton">
            {content.getValue("reset")}
          </p>
          <img src="images/refresh.svg" alt="refreshIcon" />
        </div>
        {step === 1 ? (
          <button
            onClick={() => {
              dispatch(setStep(2));
            }}
            disabled={items.length === 0 || volume >= 100}
            className={`basketBox__button basketBox__button-green basketBox__button-summary ${
              items.length === 0 || volume >= 100
                ? "basketBox__button-disabled"
                : ""
            }`}
          >
            {content.getValue("personalize")}{" "}
            <img src="./images/arrowRightWhite.svg" alt="arrowWhiteIcon" />
          </button>
        ) : (
          <button
            onClick={placeOrder}
            style={
              loading
                ? {
                    minHeight: "36px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }
                : {}
            }
            className="basketBox__button basketBox__button-green basketBox__button-summary"
          >
            {loading ? (
              <Spinner />
            ) : (
              <>
                {content.getValue("to_cart")}{" "}
                <img src="./images/basket.svg" alt="basketIcon" />
              </>
            )}
          </button>
        )}
      </div>
    </div>
  );
}

export interface ISummary {}

export default Summary;
