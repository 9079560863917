import React, { useState } from "react";

function CategoryGroup({ name, children, disabled, pers }: ICategoryGroup) {
  const [open, setOpen] = useState(false);
  const onClick = () => {
    setOpen(!open);
  };

  return (
    <li
      style={{
        opacity: disabled ? 0.5 : 1,
        cursor: disabled ? "not-allowed" : "pointer",
        pointerEvents: disabled ? "none" : "auto",
      }}
      className={pers ? "accordionPersonalizeItem" : "accordionItem"}
    >
      <div
        onClick={onClick}
        className={
          pers
            ? open
              ? "accordionPersonalizeLabel-active accordionPersonalizeLabel"
              : "accordionPersonalizeLabel"
            : open
            ? "accordionLabel--active accordionLabel"
            : "accordionLabel"
        }
      >
        {name}&nbsp;
        <img
          className="accordion_icon"
          src="images/arrowDown.svg"
          alt="arrowDownIcon"
        />
      </div>
      <div
        className={
          pers
            ? open
              ? "accordionPersonalizeContent accordionPersonalizeContent-active"
              : "accordionPersonalizeContent"
            : open
            ? "accordionContent-active accordionContent"
            : "accordionContent"
        }
      >
        {children}
      </div>
    </li>
  );
}

export interface ICategoryGroup {
  children?: React.ReactNode;
  name: string;
  disabled?: boolean;
  pers?: boolean;
}

export default CategoryGroup;
