import { FiRefreshCw } from 'react-icons/fi'
import styled from "styled-components";

const Spinner = styled(FiRefreshCw)<ISpinningIconProps>`
  @-webkit-keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }

  @keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }

  animation: icon-spin 2s infinite linear;
`

export default Spinner

export interface ISpinningIconProps {
 animationTime?: number
 size?: string | number
 animating?: boolean
}