import React from "react";
import { Product as ProductI } from "../../state/reducers/product";
import { Category } from "../../state/reducers/category";
import { useAppDispatch, useAppSelector } from "../../state/store";
import { setDescription, toggleDescriptions } from "../../state/reducers/app";
import {
  addComponentToCart,
  getCartProduct,
  getCurrentBasket,
  removeComponentToCart,
  updateProductQuantity,
  upgradeBasket,
} from "../../state/reducers/cart";
import content from "../../content";

function Product({ product, base, category, cart }: IProduct) {
  const [selected, setSelected] = React.useState(false);
  const cartProduct = useAppSelector(getCartProduct(product.id));
  const dispatch = useAppDispatch();
  const currentBase = useAppSelector(getCurrentBasket);

  const basketVolume = useAppSelector((state) => state.cart.basketVolume);
  const basketPercent = useAppSelector((state) => state.cart.volumePercent);

  const occupiedVolume = Number(basketVolume) * (Number(basketPercent) / 100);

  const productVolume = product.attributes.find(
    (a) => a.attribute === "volume"
  )?.value;

  const allowAdd =
    Number(basketVolume) === 0 ||
    Number(productVolume) + occupiedVolume <= Number(basketVolume);

  const openDescription = () => {
    dispatch(toggleDescriptions());
    dispatch(setDescription(product));
  };

  const addComponent = () => {
    if (currentBase && base) {
      dispatch(upgradeBasket(product));
    } else {
      dispatch(addComponentToCart({ product, base, cart })).then(() => {
        setSelected(true);
        setTimeout(() => setSelected(false), 1000);
      });
    }
  };

  const deselectComponent = () => {
    dispatch(removeComponentToCart({ product, base }));
  };

  const changeQuantity = (value: number) => () => {
    if (cartProduct.quantity + value === 0) {
      dispatch(removeComponentToCart({ product, base }));
    } else {
      dispatch(
        updateProductQuantity({
          ...cartProduct,
          quantity: cartProduct.quantity + value,
        })
      );
    }
  };

  return (
    <div
      className={`accordionBox ${
        selected || !!cartProduct ? "accordionBox--added" : ""
      }`}
    >
      {!!product.attributes.find((a) => a.attribute === "description") && (
        <div onClick={openDescription} className="helpIcon">
          ?
        </div>
      )}

      <div className="itemDetails">
        <img
          className="itemImage"
          src={product.image || product.integration.image}
          alt="configuratorImage"
        />
        <div className="itemData">
          <p className="itemTitle">
            {product.name || product.integration.name}
          </p>
          <div className="itemPriceBox">
            <p className="itemQuantity">
              {productVolume}
              {content.getValue("ml")}
            </p>
            <p className="itemPrice">
              {(
                Number(product.price) || Number(product.integration.price)
              ).toFixed(2)}{" "}
              {content.getValue("currency")} {content.getValue("brutto")}
            </p>
          </div>
        </div>
      </div>
      <div className="itemControls">
        {!base && !cart && !!cartProduct && (
          <div className="itemCounter">
            <div
              onClick={changeQuantity(-1)}
              className="counterIcon counterIconMinus"
            >
              <img src="images/minusIcon.svg" alt="minusIcon" />
            </div>
            <p className="itemQuantity">{cartProduct.quantity}</p>
            <div
              onClick={allowAdd ? changeQuantity(1) : undefined}
              className={`counterIcon counterIconPlus ${
                allowAdd ? "" : "button_disabled"
              }`}
            >
              <img src="images/plusIcon.svg" alt="plusIcon" />
            </div>
          </div>
        )}
        {selected ? (
          <div className="buttonAddedBox" style={{ width: "100%" }}>
            <button
              style={
                base || cart
                  ? {
                      marginLeft: "auto",
                    }
                  : {}
              }
              className="button buttonAdded"
            >
              {content.getValue("added")}
              <img src="images/checkmark.svg" alt="checkmarkIcon" />
            </button>
          </div>
        ) : !!cartProduct ? (
          <div className="buttonAddedBox" style={{ width: "100%" }}>
            <button
              onClick={deselectComponent}
              style={
                base || cart
                  ? {
                      marginLeft: "auto",
                    }
                  : {}
              }
              className="button basketBox__button buttonDelete"
            >
              {content.getValue("delete")}
              <img src="images/close.svg" alt="closeIcon" />
            </button>
          </div>
        ) : (
          <button
            onClick={allowAdd ? addComponent : undefined}
            className={`basketBox__button basketBox__button-green basketBox__button-configurator ${
              allowAdd ? "" : "button_disabled"
            }`}
            style={
              base || cart
                ? {
                    marginLeft: "auto",
                  }
                : {}
            }
          >
            {base || cart
              ? content.getValue("select")
              : content.getValue("add_to_cart")}
          </button>
        )}
      </div>
    </div>
  );
}

export interface IProduct {
  product: ProductI;
  category: Category;
  base?: boolean;
  cart?: boolean;
}

export default Product;
