import { SDate } from "./product";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface Category {
  id: number;
  name: string;
  updatedAt: SDate;
  createdAt: SDate;
  deleted: boolean;
  active: boolean;
  updateSource: String;
  integration: CategoryIntegration;
  position: number;
}

export interface CategoryIntegration {
  id: number;
  name: string;
  active: boolean;
  position: number;
  level: number;
}

const categoryAdapter = createEntityAdapter<Category>({
  selectId: (category: Category) => category.id,
  sortComparer: (a: Category, b: Category) => a.position - b.position,
});

export const fetchCategories = createAsyncThunk(
  "category/fetchCategories",
  async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/api/category/",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return await response.json();
  }
);

const categoriesSlice = createSlice({
  name: "category",
  initialState: {
    categories: categoryAdapter.getInitialState(),
    loading: false,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCategories.pending, (state, action) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      categoryAdapter.upsertMany(state.categories, action.payload);
    });
    builder.addCase(fetchCategories.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default categoriesSlice.reducer;

export const { selectAll: selectAllCategories } =
  categoryAdapter.getSelectors<RootState>(
    (state) => state.categories.categories
  );
