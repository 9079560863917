import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import CategoryGroup from "../components/category/CategoryGroup";
import { useAppDispatch, useAppSelector } from "../state/store";
import {
  getCurrentCard,
  updateCartSettings,
  uploadFile,
} from "../state/reducers/cart";
import { useDropzone } from "react-dropzone";
import content from "../content";

function Preference({ type }: { type: "basket" | "card" }) {
  const settings = useAppSelector((state) => state.cart[type]);
  const [text, setText] = React.useState(settings.text);
  const [file, setFile] = useState(null);
  const dispatch = useAppDispatch();

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFile(acceptedFiles[0]);
      dispatch(uploadFile({ file: acceptedFiles[0], type })).then(
        (res: any) => {
          const newSettings = { ...settings, logo: res.payload.image };
          dispatch(updateCartSettings({ type, settings: newSettings }));
        }
      );
    },
    [settings]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
  });

  const handleText = () => {
    const newSettings = { ...settings, text };
    dispatch(updateCartSettings({ type, settings: newSettings }));
    setText("");
  };

  const handleCheck = (checkbox: string) => (e: any) => {
    const { checked, value } = e.target;
    const newSettings = { ...settings, [checkbox]: checked };
    dispatch(updateCartSettings({ type, settings: newSettings }));
  };

  const deleteFile = () => {
    const newSettings = { ...settings, logo: null };
    dispatch(updateCartSettings({ type, settings: newSettings }));
    setFile(null);
  };

  useEffect(() => {
    setText(settings.text);
  }, [settings]);

  return (
    <>
      <div className="leftCol">
        <div className="dragdropBox">
          <div className="checkboxContainer">
            <label className="checkboxLabel">
              <input
                onChange={handleCheck("ownLogo")}
                className="checkbox"
                checked={settings.ownLogo}
                type="checkbox"
              />
              <p className="checkboxText">{content.getValue("own_logo")}</p>
            </label>
          </div>

          {settings.ownLogo && (
            <>
              {file ? (
                <div
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    marginRight: "18px",
                  }}
                  className="loading"
                >
                  <img
                    onClick={deleteFile}
                    className="closeLoading"
                    src="images/closeGreen.svg"
                    alt="closeIcon"
                  />
                  <p className="loadingText">{(file as any)?.name}</p>
                </div>
              ) : (
                <div {...getRootProps()} className="dragDrop">
                  <input {...getInputProps()} />
                  <img src="images/cloud.svg" alt="cloudIcon" />
                  <p className="dragDropText">
                    {content.getValue("drag_n_drop")}{" "}
                    <a className="dragDropLink">
                      {content.getValue("browse_files")}
                    </a>
                  </p>
                </div>
              )}
            </>
          )}
        </div>
        <div className="inputBox">
          <div className="checkboxContainer">
            <label className="checkboxLabel">
              <input
                onChange={handleCheck("ownText")}
                className="checkbox"
                type="checkbox"
                checked={settings.ownText}
              />
              <p className="checkboxText">{content.getValue("own_text")}</p>
            </label>
          </div>
          {settings.ownText && (
            <div className="dragDropInput">
              <input
                value={text}
                onChange={(e) => setText(e.currentTarget.value)}
                className="input"
                placeholder={content.getValue("text_placeholder")}
              />
              {text && text !== settings.text && (
                <button onClick={handleText} className="applyInput">
                  {content.getValue("apply")}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      {settings.ownLogo && settings.logo && (
        <div className="imageBox">
          <img className="imagePersonalize" src={settings.logo} alt="image" />
        </div>
      )}
    </>
  );
}

function Personalization({}: IPersonalization) {
  const cart = useAppSelector(getCurrentCard);

  return (
    <ul className="accordion">
      <CategoryGroup
        pers={true}
        name={content.getValue("personalization_basket")}
      >
        <Preference type={"basket"} />
      </CategoryGroup>
      <CategoryGroup
        disabled={!cart}
        pers={true}
        name={content.getValue("personalization_card")}
      >
        <Preference type={"card"} />
      </CategoryGroup>
    </ul>
  );
}

export interface IPersonalization {}

export default Personalization;
