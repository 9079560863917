import React from "react";
import Step from "./Step";
import content from "../../content";

function Stepper({ step }: IStepper) {
  return (
    <div className="steps">
      <Step
        activeStep={step}
        step={1}
        text={content.getValue("select_products")}
      />
      <img
        className="steps__divider"
        src="images/arrowRight.svg"
        alt="arrowRightIcon"
      />
      <Step activeStep={step} step={2} text={content.getValue("personalize")} />
    </div>
  );
}

export interface IStepper {
  step: number;
}

export default Stepper;
